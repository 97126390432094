
import { App, defineComponent, UnwrapRef } from "vue";
import {
  ApiHelper,
  Logger,
  PageService,
  ScreenSpinner,
  toNormaliseDomId,
  toStr,
} from "table";

export default defineComponent({
  name: "DefaultPage",
  components: { ScreenSpinner },
  data() {
    return {
      loading: false,
      errorMessage: [] as string[],
      app: null as null | App<Element>,
    };
  },
  computed: {
    backendPage(): string {
      return (this.$route.params as any).backend_page;
    },
    frontendPage(): string {
      return (this.$route.params as any).frontend_page;
    },
    idPage(): string {
      return toNormaliseDomId(
        `default_page_${this.backendPage ?? this.frontendPage}`,
      );
    },
    componentUrl(): string {
      return this.backendPage
        ? `/api/pages?name=${this.backendPage}`
        : `/default-pages/${this.frontendPage}`;
    },
  },
  async mounted() {
    await this.init();
  },
  unmounted() {
    this.app?.unmount();
  },
  methods: {
    async init() {
      this.errorMessage = [];
      this.loading = true;
      try {
        const root = this.$refs.root as HTMLDivElement;
        const { app } = await PageService.createDefaultPage(
          `#${this.idPage}`,
          this.componentUrl,
          this.$route,
          { root },
        );
        this.app = app as any as UnwrapRef<App<Element>>;
      } catch (ex) {
        Logger.error("Ошибка при попытке отобразить DefaultPage:", { ex });
        if (ex instanceof Error && "stack" in ex) {
          this.errorMessage.push("Loc:\n" + toStr((ex as any).loc) + "\n\n");
          this.errorMessage.push("Stack:\n" + ex.stack! + "\n\n");
        } else {
          this.errorMessage = [await ApiHelper.getErrorMessage(ex)].filter(
            Boolean,
          );
        }
      }

      this.loading = false;
    },
  },
});
