import { setBlockTracking as _setBlockTracking, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "default-page-container default-page-padding-inline",
  style: {"font-size":"30px","color":"#a80000"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (
      _setBlockTracking(-1),
      _cache[0] = _createElementVNode("div", {
        id: _ctx.idPage,
        ref: "root",
        class: "table-scroll-container"
      }, null, 8, ["id"]),
      _setBlockTracking(1),
      _cache[0]
    ),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ScreenSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage.length)
      ? (_openBlock(), _createElementBlock("pre", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessage, (msg, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: msg + i
            }, [
              _createTextVNode(_toDisplayString(msg), 1)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}